import api from '../../utils/api'
// 登录
export async function get_order_list (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         orders: {
  //           total_elements: 7,
  //           data: [
  //             {
  //               title: '核磁共振共振',
  //               order_state: 4,
  //               order_state_label: '等待报告',
  //               location_label: '罗湖医院检验点',
  //               patient_name: '张三',
  //               patient_age: 34,
  //               report_time: new Date(),
  //               report_id: 1,
  //               order_id:1,
  //             },
  //             {
  //               title: '核磁共振共振',
  //               order_state: 4,
  //               order_state_label: '等待报告',
  //               location_label: '罗湖医院检验点',
  //               patient_name: '张三',
  //               patient_age: 34,
  //               report_time: new Date(),
  //               report_id: 2,
  //               order_id:2,
  //             },
  //             {
  //               title: '核磁共振共振',
  //               order_state: 5,
  //               order_state_label: '已出报告',
  //               location_label: '罗湖医院检验点',
  //               patient_name: '张三',
  //               patient_age: 34,
  //               report_time: new Date(),
  //               report_id: 3,
  //               order_id:3,
  //             },
  //             {
  //               title: '核磁共振共振',
  //               order_state: 4,
  //               order_state_label: '等待报告',
  //               location_label: '罗湖医院检验点',
  //               patient_name: '张三',
  //               patient_age: 34,
  //               report_time: new Date(),
  //               report_id: 4,
  //               order_id:4,
  //             },
  //             {
  //               title: '核磁共振共振',
  //               order_state: 5,
  //               order_state_label: '已出报告',
  //               location_label: '罗湖医院检验点',
  //               patient_name: '张三',
  //               patient_age: 34,
  //               report_time: new Date(),
  //               report_id: 5,
  //               order_id:5,
  //             }
  //           ]
  //         }
  //       }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v1/mp/orders', {params:payload})
}

